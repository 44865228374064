
  import { Component, Watch } from 'vue-property-decorator'
  import { GForm } from '@/components/forms/GForm'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { plainToInstance } from 'class-transformer'
  import { fixDate, fixPrice, parseToNumber, sendNotification } from '@/utils/general'
  import LinkedAuto from '@/components/forms/fields/LinkedAuto.vue'
  import GFiles from '@/components/core/files/GFiles.vue'
  import { Evaluation, Quota } from '@/entities/loans'
  import { Form } from '@/entities/public/Resource/metadata'
  import FieldTitle from '@/components/forms/fields/FieldTitle.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import dayjs from 'dayjs'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import { Stock } from '@/entities/sales'

@Component({
  components: { GAlert, GDatePicker, FieldTitle, GFiles, LinkedAuto, BaseForm },
  methods: { fixPrice },
  computed: {},
})
  export default class QuotaForm extends GForm {
  quota: Quota = plainToInstance(Quota, {})
  evaluation: Evaluation = plainToInstance(Evaluation, {})
  stock: Stock = plainToInstance(Stock, {})

  declare $refs: {
    form: HTMLFormElement
  };

  title = ''
  showDetail = false

  formData = {
    product: null,
    term: null,
    monthlyRate: null,
    quotaValue: null,
    specialQuota: null,
    futureValue: null,
    date: dayjs().add(1, 'month').format('YYYY-MM-DD'),
    insurance: null,
    fee: null,
  }

  fields = {
    product: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    term: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    monthlyRate: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    quotaValue: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    specialQuota: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    futureValue: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    insurance: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    fee: {
      properties: {},
      items: [],
      rules: this.fieldRequired,
    },
    date: {
      properties: {},
      rules: this.fieldRequired,
    },
  }

  metadata = {}
  metadataCollection = {}

  async mounted () {
    await this.setMetadata()
    const { uid, model, title, metadataCollection } = this

    if (!isNaN(uid) && model === 'Evaluation') {
      await this.getEvaluationInfo(uid)
    }

    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(this.quota?.id))
    }
    await this.setTheBackup()
  }

  async getEvaluationInfo (id) {
    const evaluation = await this.fetchData({
      query: { name: 'fetch', model: 'Evaluation', params: { id } },
      force: true,
    })

    const financing = await this.fetchData({
      query: { name: 'fetch', model: 'Financing', params: { id: evaluation.financing.id } },
      force: true,
    })

    this.stock = financing.saleOrder.deal.stock
    this.evaluation = evaluation
    this.evaluation.financing = financing
  }

  async setMetadata () {
    const { metadata } = this.getForm('Quota', 'financer_quota')
    const { form, fields } = metadata as Form

    this.metadataCollection = metadata
    this.title = form.title
    this.fields.product.properties = fields.product.properties
    this.fields.fee.properties = fields.fee.properties
    this.fields.monthlyRate.properties = fields.monthlyRate.properties
    this.fields.term.properties = fields.term.properties
    this.fields.futureValue.properties = fields.futureValue.properties
    this.fields.quotaValue.properties = fields.quotaValue.properties
    this.fields.specialQuota.properties = fields.specialQuota.properties
    this.fields.date.properties = fields.date.properties
    this.fields.insurance.properties = fields.insurance.properties
  }

  async setTheBackup () {
    const { backup, uid, evaluation } = this
    if (!backup) {
      await this.close()
      return
    }
    const evaluationForm = 'evaluationForm' in backup && backup.evaluationForm

    const quotaForm = 'quotaForm' in backup && backup.quotaForm

    if (!evaluationForm || !uid) {
      await this.close()
    }

    if (quotaForm) {
      this.formData = quotaForm
    }

    if (evaluationForm) {
      const agreementId = backup?.evaluation?.agreement?.id || evaluation?.agreement?.id
      this.fields.product.items = await this.fetchData({
        query: { name: 'find', model: 'AgreementProduct' },
        filter: {
          approaches: { business_approach: { id: { _eq: evaluationForm.businessApproach.id } } },
          agreement: { id: { _eq: agreementId } },
        },
      })

      const items = await this.fetchData({
        query: { name: 'find', model: 'AgreementProduct' },
        filter: { agreement: { type: { name: { _eq: 'insurance' } }, id_person: { _eq: evaluation?.agreement?.person?.id } } },
      })

      this.fields.insurance.items = items.map(item => {
        return { id: item.id, description: item.product.description }
      })
    }
  }

  saveBackup () {
    const { backup, formData } = this
    if (backup) {
      backup.quotaForm = formData
      this.setBackup(backup)
    } else {
      this.setBackup({ quotaForm: formData })
    }
  }

  async send () {
    if (!this.$refs.form.validate()) {
      return
    }
    const { backup, formData } = this
    this.loadingForm = true
    if (!backup) {
      this.setBackup({ ...formData })
    } else {
      backup.quotaForm = formData
      this.setBackup(backup)
    }

    await this.close()
    this.loadingForm = false
  }

  get bindTerm () {
    const { fields: { term } } = this

    if (!term) return null
    return { ...term.properties, items: term.items }
  }

  get bindProduct () {
    const { fields: { product } } = this

    if (!product) return null
    return { ...product.properties, items: product.items }
  }

  get bindInsurance () {
    const { fields: { insurance } } = this

    if (!insurance) return null
    return { ...insurance.properties, items: insurance.items }
  }

  get change () {
    const { formData } = this

    return JSON.stringify(formData)
  }

  get isVariantPurchasable () {
    const { formData: { term } } = this

    return Boolean(term?.purchasable)
  }

  get isVariantSpecial () {
    const { formData: { term } } = this

    return Boolean(term?.isSpecial)
  }

  get isVariantRegular () {
    const { formData: { term } } = this

    return Boolean(term?.isRegular)
  }

  get warranty () {
    const { evaluation } = this

    return evaluation?.financing?.warrantyAmount || 0
  }

  @Watch('formData.product', { immediate: true })
  async onProductChange (val) {
    if (val) {
      if (val.name === 'intelligent') {
        this.formData.specialQuota = Math.round(this.stock.currentPrice.amount / 2)
      } else {
        this.formData.specialQuota = null
      }

      this.formData.term = null
      this.fields.term.items = await this.fetchData({
        query: { name: 'find', model: 'Variant' },
        filter: { agreement_product: { id: { _eq: val.id } } },
      })
    }
  }

  @Watch('formData', { immediate: true, deep: true })
  onQuotaChange (val) {
    this.disableSend = false
    const { backup } = this
    if (!backup) return
    const { evaluationForm: { quotas } } = backup

    const exist = quotas?.some(quota => {
      const isSameVariant = quota.variant.id === val.term?.id
      const isSameRate = parseToNumber(quota.rate) === parseToNumber(val.monthlyRate)
      const isSameFee = parseToNumber(quota.fee) === parseToNumber(val.fee)
      const isSameStartPayment = quota.startPayment.isSame(dayjs(fixDate(val.date)))
      const future = val.futureValue && parseToNumber(val.futureValue)
      const isCoverage = parseToNumber(quota.coverage) === future

      return isSameVariant && isSameRate && isSameFee && isSameStartPayment && isCoverage
    })
    if (exist) {
      sendNotification('Cuota ya registrada', 'error')
      this.disableSend = true
    }
  }

  @Watch('formData.specialQuota', { immediate: true })
  onSpecialQuotaChange (val) {
    if (this.isVariantPurchasable) {
      this.formData.futureValue = val
    }
  }
  }
